import React, { useState, useEffect, useContext, useCallback } from 'react';
import { useIsLoading } from './useIsLoading';
import request from '../services/Http';
import 'react-toastify/dist/ReactToastify.css';
import Handsontable from 'handsontable';
import {
  ODD_ROW_CLASS,
  handleActionsEnum,
} from '../../views/financial-forecast/components/constants';
import { getUserInfo, isJSONValid } from 'utils/services/Helpers';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';
import { SocketContext } from '../context/socketContext';
import useApprovals from './useApprovals';
import { SET_IS_LOADING } from 'utils/context/store/Constants';
import { Context } from 'utils/context/store/Store';
import * as XLSX from 'xlsx';

export default function useFinancialForecast(isForApprover = false) {
  const headerAlignments = new Map([
    ['9', 'htCenter'],
    ['10', 'htRight'],
    ['12', 'htCenter']
  ]);
  const API_END_POINT = 'fc-header';
  const API_FF_Structure = 'transaction-financial-forecast';
  const { setIsLoading, isLoading } = useIsLoading();
  const [renderTable, setRenderTable] = React.useState(false);
  const [headers, setHeaders] = useState(null);
  const [dataSource, setDataSource] = useState(null);
  const [FFStructureToDisplayWithData, setFFStructureToDisplayWithData] = useState(null);
  const [FFStructureToDisplayForHeader, setFFStructureToDisplayForHeader] = useState(null);
  const [dropdownData, setDropdownData] = useState(null)
  const [cyclePeriod, setCyclePeriod] = useState(null);
  const [hfm, setHfm] = useState(null);
  const [businessCategory, setBusinessCategory] = useState(null);
  const [client, setClient] = useState(null);
  const [currency, setCurrency] = useState(null);
  const [year1, setYear1] = useState(null);
  const [year2, setYear2] = useState(null);
  const [version1, setVersion1] = useState(null);
  const [version2, setVersion2] = useState(null);
  const [selectedView, setSelectedView] = useState('single');
  const [data, setData] = useState([])
  const [objectForApproval, setObjectForApproval] = useState([])
  const [exportData, setExportData] = useState([])
  const [sheetTwo, setSheetTwo] = useState(null)
  const [isSheetSubmitted, setIsSheetSubmitted] = useState(false)
  const [hasSheetEdited, setHasSheetEdited] = useState(false)
  const [canSubmitSheet, setCanSubmitSheet] = useState(false)
  const [canActionSheet, setCanActionSheet] = useState(false)
  const [canApproverEdit, setCanApproverEdit] = useState(false)
  const [canEdit, setCanEdit] = useState(false);
  const [sheetEdit, setSheetEdit] = useState(false);
  const [isRollback, setIsRollback] = useState(false);
  const [queueMessage, setQueueMessage] = useState(null);
  const [rateMessage, setRateMessage] = useState(null);
  const [customBorders, setCustomBorders] = useState([]);
  const [openMonths, setOpenMonths] = useState([]);
  const [preOrPostStated, setPreOrPostStated] = useState(0);
  const [sheetType, setSheetType] = useState(null);
  const socket = useContext(SocketContext);
  const [{ }, dispatch] = useContext(Context)
  const isExpense = dropdownData?.masterBusinessCategoryId?.find(d => d.id === businessCategory)?.isExpenseRelated === 1
  const {
    bulkRollback,
    approveOrReject,
  } = useApprovals();

  useEffect(() => setIsLoading(false), []);
  useEffect(() => {
    const fetchData = async () => {
      await getDataForTable();
    };
    fetchData();
  }, [hfm, selectedView, client, year1, year2, version1, version2, preOrPostStated]);
  useEffect(() => {
    if (client === null && !isForApprover) {
      setHeaders(null)
      setRenderTable(true);
      setDataSource(null);
    }
    if (isExpense) {
      setHeaders(null)
      setRenderTable(true);
      setDataSource(null);
    }
    if (hfm === null && isForApprover) {
      setHeaders(null)
      setRenderTable(true);
      setDataSource(null);
    }
    resetSettings();
  }, [cyclePeriod, businessCategory, hfm, client]);
  useEffect(() => {
    if (year2 && year1) {
      // setHeaders(__ffData.headers.forecast);
      // setDataSource(__ffData.dataSource.forecast);
      // setRenderTable(true);
      const header = isJSONValid(FFStructureToDisplayForHeader?.[0]?.header);
      setHeaders(header?.header);
      setDataSource(FFStructureToDisplayWithData);
      setRenderTable(true);
    }
    else if (year1) {
      // setHeaders(__ffData.headers.history);
      const header = isJSONValid(FFStructureToDisplayForHeader[0].header);
      setHeaders(header?.header);
      // setDataSource(__ffData.dataSource.history);
      setDataSource(FFStructureToDisplayWithData);
      setRenderTable(true);
    }
  }, [year2]);
  useEffect(() => {
    // Check if the data is available
    if (FFStructureToDisplayForHeader && FFStructureToDisplayForHeader.length > 0) {
      const header = isJSONValid(FFStructureToDisplayForHeader[0]?.header);
      setHeaders(header?.header);
      setDataSource(FFStructureToDisplayWithData);

      // Set renderTable to true only after data is set
      setRenderTable(true);
    }
  }, [FFStructureToDisplayForHeader]);
  useEffect(() => { }, [queueMessage, canEdit, sheetEdit]);

  const resetSettings = () => {
    if (hfm === null || client === null || year1 === null) {
      setRenderTable(false);
      handleAction(handleActionsEnum.YEAR_1);
      handleAction(handleActionsEnum.YEAR_2);
    }
  };

  const addClassesToRows = (TD, row, column, prop, value, cellProperties) => {
    // Adding classes to `TR` just while rendering first visible `TD` element
    if (column !== 0) {
      return;
    }

    const parentElement = TD.parentElement;

    if (parentElement === null) {
      return;
    }

    // Add class to odd TRs
    if (row % 2 === 0) {
      Handsontable.dom.addClass(parentElement, ODD_ROW_CLASS);
    } else {
      Handsontable.dom.removeClass(parentElement, ODD_ROW_CLASS);
    }
  };

  const drawCheckboxInRowHeaders = function drawCheckboxInRowHeaders(row, TH) {
    const input = document.createElement('input');

    input.type = 'checkbox';

    if (row >= 0 && this.getDataAtRowProp(row, '0')) {
      input.checked = true;
    }

    Handsontable.dom.empty(TH);

    TH.appendChild(input);
  };

  const changeCheckboxCell = function changeCheckboxCell(event, coords) {
    const target = event.target;

    if (coords.col === -1 && event.target && target.nodeName === 'INPUT') {
      event.preventDefault(); // Handsontable will render checked/unchecked checkbox by it own.

      this.setDataAtRowProp(coords.row, '0', !target.checked);
    }
  };

  const alignHeaders = (index, TH, row, headers) => {
    headers?.[row]?.[index]?.tooltip && TH.setAttribute('title', headers[row][index]?.tooltip);
  };

  const getSetupHeader = async () => {
    setIsLoading(true);
    dispatch({ type: SET_IS_LOADING, payload: true });
    try {
      const res = await request.get(API_END_POINT);
      if (res) {
        // toast.success(res.data.data);
        return res.data;
      }
    } catch (e) {
    } finally {
      dispatch({ type: SET_IS_LOADING, payload: false });
      setIsLoading(false);
    }
  };

  const getDropdownData = async () => {
    setIsLoading(true);
    dispatch({ type: SET_IS_LOADING, payload: true });
    try {
      const res = await request.get(`${API_FF_Structure}/dd-data`);
      setDropdownData(prevState => ({ ...prevState, ...res?.data?.dropdownData ?? {} }));
      await getDataForTable()
    } catch (e) {
      console.error('Error fetching dropdown data:', e);
    } finally {
      dispatch({ type: SET_IS_LOADING, payload: false });
      setIsLoading(false);
    }
  };

  const buildQueryParams = () => {
    const params = [
      businessCategory && `masterBusinessCategoryId=${businessCategory}`,
      !isForApprover && `masterOrgHFMId=${hfm}`,
      !isForApprover && `clientGroupId=${client}`,
      !isForApprover && isExpense && `restated=${preOrPostStated}`,
      !isForApprover && isExpense && `isExpense=${isExpense}`,
      isForApprover && `consolidated=${true}`,
      isForApprover && sheetType === 2 && `sheetType=${sheetType}`,
      `masterBudgetCyclePeriodId=${cyclePeriod}`,
      !isForApprover && `masterRFAgencyId=${dropdownData?.masterOrgHFMId?.find(h => h.id === hfm)?.masterRFAgencyId}`,
      `masterRFHeaderId=${isExpense ? dropdownData?.masterBudgetCyclePeriodId?.find(h => h.id === cyclePeriod)?.expenseHeaderId : dropdownData?.masterBudgetCyclePeriodId?.find(h => h.id === cyclePeriod)?.masterRFHeaderId}`,
      year1 && `year1=${year1}`,
      year2 && `year2=${year2}`,
      version1 && `version1=${version1}`,
      `isLatest=${version1 ? dropdownData?.versions?.find(v => v.value === parseInt(version1))?.isLatest ? 1 : 0 : 1}`,
      version2 && `version2=${version2}`,
    ].filter(Boolean).join('&');

    return `?${params}`;
  };

  const getDataForTable = async () => {
    if (!isForApprover && isExpense && (!businessCategory || !hfm || preOrPostStated === null)) return; // Ensure the required parameters are available
    if (!isForApprover && (!businessCategory || !hfm || !client) && !isExpense) return; // Ensure the required parameters are available
    if (isForApprover && (!cyclePeriod || !hfm?.length)) return;

    const currencySelected = isForApprover ? Array.from(
      new Set(
        dropdownData?.masterOrgHFMId
          ?.filter((h) => hfm?.includes(h.id)) // Filter based on the condition
          ?.map((h) => h.master_currency?.id) // Extract the 'name' property
          ?.filter(Boolean) // Remove any undefined or null values
      )
    ) : null

    setIsLoading(true);
    dispatch({ type: SET_IS_LOADING, payload: true });
    try {
      const res = isForApprover ? await request.post(`${API_FF_Structure}/approver${buildQueryParams()}`, {
        masterOrgHFMId: hfm,
        clientGroupId: client,
        currencySelected
      }) : await request.get(`${API_FF_Structure}${buildQueryParams()}`);
      if (res) {
        if (version1 || version2) delete res?.data?.dropdownData?.['versions']
        setDropdownData(prvState => {
          return { ...prvState, ...res.data.dropdownData }
        });
        setFFStructureToDisplayWithData(res?.data?.data);
        setFFStructureToDisplayForHeader(res?.data?.headers);
        setIsSheetSubmitted(res.data.isSubmitted)
        setData(res.data.savedTransactions)
        setCanSubmitSheet(res.data.canSubmit)
        setCanActionSheet(res.data.needApproval)
        setObjectForApproval(res.data.objectForApproval)
        setExportData(res.data.exportData)
        setCanApproverEdit(res.data.canApproverEdit)
        setIsRollback(res.data.isRollback)
        setOpenMonths(res.data.months)
        const header = isExpense ? dropdownData?.masterBudgetCyclePeriodId.find(c => c.id === cyclePeriod)?.expenseHeader : dropdownData?.masterBudgetCyclePeriodId.find(c => c.id === cyclePeriod)?.header
        const bc = dropdownData?.masterBusinessCategoryId.find(c => c.id === parseInt(businessCategory))?.isClientRelated
        const isNoneClient = bc === 0 && !isExpense
        if (isNoneClient && !header[1].includes("Restated To")) {
          header[0].splice(5, 0, null)
          header[1].splice(4 + 1, 0, "Restated To");
        }
        if (!isNoneClient && header[1].includes("Restated To")) {
          const agencyStatementIndex = header[1].indexOf("Restated To");
          if (agencyStatementIndex !== -1) {
            header[0].splice(5, 1)
            header[1].splice(agencyStatementIndex, 1); // Remove "Agency Statement"
          }
        }
        setHeaders(header)
        const dataSource = res?.data?.data
        setDataSource(dataSource);
        generateBorders(dataSource, header?.[1]?.length - 1)
        setRenderTable(true);
        setRateMessage(res.data.rateMessage)
        if (res.data?.sheet2) setSheetTwo(res.data?.sheet2)
        return res.data;
      }
    } catch (e) {
      console.error('Error fetching table data:', e);
    } finally {
      dispatch({ type: SET_IS_LOADING, payload: false });
      setIsLoading(false);
    }
  };

  const generateBorders = (data, columnCount = 18) => {
    const borders = [];
    let currentRow = 0;
    const traverse = (node) => {
      const startRow = currentRow;
      currentRow++; // Move to the next row for this node

      if (node.__children) node.__children.forEach((child) => traverse(child));
      const endRow = currentRow - 1;

      // Add borders only for level_1 nodes
      if (node.level_1) borders.push({
        range: {
          from: { row: startRow, col: 0 },
          to: { row: endRow, col: columnCount },
        },
        top: { width: 3, color: 'black' },
        left: { width: 3, color: 'black' },
        bottom: { width: 3, color: 'black' },
        right: { width: 3, color: 'black' },
      });
    };
    data.forEach((node) => traverse(node));
    // Set borders only if they have changed
    setCustomBorders((prevBorders) => {
      if (JSON.stringify(prevBorders) !== JSON.stringify(borders)) {
        return borders;
      }
      return prevBorders;
    });
  }

  const submitHeader = async (body) => {
    setIsLoading(true);
    dispatch({ type: SET_IS_LOADING, payload: true });
    try {
      const res = await request.post(API_END_POINT, body);
      if (res) {
        toast.success(res.data.data);
      }
    } catch (e) {
    } finally {
      dispatch({ type: SET_IS_LOADING, payload: false });
      setIsLoading(false);
    }
  };

  const removeHeaderVersion = async (idx) => {
    setIsLoading(true);
    dispatch({ type: SET_IS_LOADING, payload: true });
    try {
      const res = await request.delete(API_END_POINT, { data: { headerIds: idx } });
      if (res) {
        toast.success(res.data);
      }
    } catch (e) {
    } finally {
      dispatch({ type: SET_IS_LOADING, payload: false });
      setIsLoading(false);
    }
  };

  const saveAsExcel = () => {
    try {
      if (!Array.isArray(exportData) || exportData.length === 0) {
        console.error("Invalid or empty exportData array");
        return;
      }

      const worksheet = XLSX.utils.json_to_sheet(exportData);

      worksheet["!freeze"] = { xSplit: 0, ySplit: 1 };
      const range = XLSX.utils.decode_range(worksheet['!ref']);
      for (let col = range.s.c; col <= range.e.c; col++) {
        const cell = XLSX.utils.encode_cell({ r: 0, c: col });
        worksheet[cell].s = { locked: true };
      }

      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "Rolling Forecast");

      const fileName = `Rolling_Forecast_${new Date().getTime()}.xlsx`;

      XLSX.writeFile(workbook, fileName);
    } catch (error) {
      console.error("Error in downloading Excel file:", error);
    }
  };

  const clearDropdownsAfterEdit = () => {
    handleAction(handleActionsEnum.CYCLE_PERIOD, null)
    setSheetEdit(false)
    setCanEdit(false)
    setHasSheetEdited(false)
    setDataSource(null)
    setHeaders(null)
    setData([])
    setCyclePeriod(null);
    setBusinessCategory(null);
    setHfm(null);
    setClient(null);
    setCurrency(null);
    setYear1(null);
    setYear2(null);
    setVersion1(null);
    setVersion2(null);
    socket.emit('handle-rf-page', { action: true, userId: getUserInfo()?.id });
  }

  const handleAction = (type = null, value = null) => {
    switch (type) {
      case handleActionsEnum.CYCLE_PERIOD:
        setRenderTable(false);
        setCyclePeriod(value);
        setBusinessCategory(null);
        setHfm(null);
        setClient(null);
        setYear1(null);
        setVersion1(null);
        break;
      case handleActionsEnum.BUSINESS_CATEGORY:
        setRenderTable(false);
        setBusinessCategory(value);
        setHfm(null);
        setClient(null);
        setYear1(null);
        setVersion1(null);
        break;
      case handleActionsEnum.HFM:
        setRenderTable(false);
        setHfm(value);
        if (isForApprover) setRenderTable(true)
        setClient(null);
        setYear1(null);
        setVersion1(null);
        break;
      case handleActionsEnum.CLIENT:
        setClient(value);
        if (isForApprover) setRenderTable(true)
        setVersion1(null);
        setYear1(null);
        break;
      case handleActionsEnum.CURRENCY:
        setCurrency(value);
        break;
      case handleActionsEnum.YEAR_1:
        setYear1(value);
        setRenderTable(true)
        break;
      case handleActionsEnum.YEAR_2:
        setYear2(value);
        break;
      case handleActionsEnum.VERSION_1:
        setVersion1(value);
        break;
      case handleActionsEnum.VERSION_2:
        setVersion2(value);
        break;
      case handleActionsEnum.SHEET_EDIT:
        setSheetEdit(value);
        if (value === true) {
          // const fn = async () => await getDataForTable()
          // fn()
          setQueueMessage(null);
          socket.emit('handle-rf-page', {
            userId: getUserInfo()?.id, payload:
            {
              masterBudgetCyclePeriodId: cyclePeriod,
              masterBusinessCategoryId: businessCategory,
              masterOrgHFMId: hfm,
              clientGroupId: client,
              masterRFAgencyId: dropdownData?.masterOrgHFMId?.find(h => h.masterBusinessCategoryId === businessCategory && h.id === hfm)?.master_rf_agency?.id,
            }
          });
          setRenderTable(true);
        }
        break;
      case handleActionsEnum.PRE_OR_POST_STATED:
        setPreOrPostStated(value);
        setRenderTable(true)
        if (!value) setDataSource([])
        break;
      case handleActionsEnum.SHEET_TYPE:
        setSheetType(value);
        setCyclePeriod(null);
        setBusinessCategory(null);
        setHfm(null);
        setClient(null);
        setRenderTable(true)
        setDataSource([])
        break;
      default:
        setCyclePeriod(value);
        setBusinessCategory(value);
        setHfm(value);
        setClient(value);
        setCurrency(value);
        setYear1(value);
        setYear2(value);
        setVersion1(value);
        setVersion2(value);
        setSheetType(value)
        break;
    }
  };

  const submitSheet = async () => {
    try {
      socket.emit('handle-rf-page', {
        userId: getUserInfo()?.id, payload:
        {
          masterBudgetCyclePeriodId: cyclePeriod,
          masterBusinessCategoryId: businessCategory,
          masterOrgHFMId: hfm,
          clientGroupId: client,
          masterRFAgencyId: dropdownData?.masterOrgHFMId?.find(h => h.masterBusinessCategoryId === businessCategory && h.id === hfm)?.master_rf_agency?.id,
          canSubmitSheet: true,
          objToPlay: {
            dataSource, data,
            dataWithIds: dropdownData.data,
            payload: {
              masterBudgetCyclePeriodId: cyclePeriod,
              masterBusinessCategoryId: businessCategory,
              masterOrgHFMId: hfm,
              clientGroupId: client,
              masterRFAgencyId: dropdownData?.masterOrgHFMId?.find(h => h.masterBusinessCategoryId === businessCategory && h.id === hfm)?.master_rf_agency?.id,
              masterRFHeaderId: isExpense ? dropdownData?.masterBudgetCyclePeriodId?.find(h => h.id === cyclePeriod)?.expenseHeaderId : dropdownData?.masterBudgetCyclePeriodId?.find(h => h.id === cyclePeriod)?.masterRFHeaderId,
              isSheetSubmitted,
              canSubmitSheet: true,
              isRollback,
            }
          }
        }
      });
    }
    catch (e) { }
  }

  const handleSheetSubmit = async () => {
    let dataToPost = []
    let isBusinessLead = dropdownData?.collaboration?.isBusinessLead
    let dataWithIds = dropdownData?.data
    if (!isExpense && (isBusinessLead === true || canApproverEdit) && dataSource?.length) {
      if (canApproverEdit && data.length) dataToPost = data
      if (!canApproverEdit && data.length && (data?.filter(d => !d?.isActual).every(d => d.newValue === null || d.newValue === 0) ? true : data?.filter(d => !d?.isActual).every(d => d.isBusinessLead === true && d.isPracticeHead === true) || isRollback)) dataToPost = data
      if (!data.length && !dataToPost?.length) dataToPost = mapIdsToEmptySheetStructure(dataWithIds, generateSheetDataFromDataSourceIfEmpty(dataSource))
      console.info('dataToPost', dataToPost, data?.filter(d => !d?.isActual).every(d => d.isBusinessLead === true && d.isPracticeHead === true), data)
      if (dataToPost?.length)
        return Swal.fire({
          title: "Are you sure?",
          text: "You'll not be able to edit the sheet.",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes, proceed",
          heightAuto: false,
          height: "200px",
          customClass: {
            container: '__swal__continer__ __font__family__regular',
            confirmButton: '__default__button__layout __btn__green __primary__color __unset__border',
            cancelButton: '__default__button__layout __primary__color __unset__border'
          }
        })
          .then(async (res) => {
            if (res.isConfirmed) {
              dataToPost?.map(d => {
                d.isSubmitted = true
                d.isDraft = false
                return d
              })
              await createUpdateTransactions(dataToPost);
            }
          })
      else toast.info("Submission failed: The sheet is not in a valid state for submission. Please check your data.")

    }
    else if (isExpense && dataSource?.length) {
      if (canApproverEdit && data.length) dataToPost = data
      if (!canApproverEdit && data.length || isRollback) dataToPost = data
      if (!data.length && !dataToPost?.length) dataToPost = mapIdsToEmptySheetStructure(dataWithIds, generateSheetDataFromDataSourceIfEmpty(dataSource))
      if (dataToPost?.length)
        return Swal.fire({
          title: "Are you sure?",
          text: "You'll not be able to edit the sheet.",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes, Submit it!",
          heightAuto: false,
          height: "200px",
          customClass: {
            container: '__swal__continer__ __font__family__regular',
            confirmButton: '__default__button__layout __btn__green __primary__color __unset__border',
            cancelButton: '__default__button__layout __primary__color __unset__border'
          }
        })
          .then(async (res) => {
            if (res.isConfirmed) {
              dataToPost?.map(d => {
                d.isSubmitted = true
                d.isDraft = false
                return d
              })
              await createUpdateTransactions(dataToPost);
            }
          })
      else toast.info("Submission failed: The sheet is not in a valid state for submission. Please check your data.")
    }
    else toast.info("Action not allowed: You don't have the necessary permissions")
  }

  const mapIdsToEmptySheetStructure = (dataWithIds, data) => {
    const idMap = new Map();

    dataWithIds.forEach((item) => {
      const key = `${item.level_1 || ""}|${item.level_2 || ""}|${item.level_3 || ""}`;
      idMap.set(key, item);
    });

    return data.map((d) => {
      const key = `${d.level_1 || ""}|${d.level_2 || ""}|${d.level_3 || ""}`;
      return {
        ...d,
        ...(idMap.get(key) || {}),
      };
    });
  };

  const getColumnName = (colIndex) => {
    // Access the row that contains the month labels (row index 2 in your case)
    const monthHeaders = headers[1];
    const column = monthHeaders ? monthHeaders[colIndex] : null;
    if (column && column?.label) return column.label;
    return null;
  };

  const generateSheetDataFromDataSourceIfEmpty = (inputData) => {
    const result = [];
    let currentRowIndex = 0;

    const traverse = (node, parentLevels = {}) => {
      const currentLevels = { ...parentLevels };

      Object.entries(node).forEach(([key, value]) => {
        if (key.startsWith("level_") && value) {
          currentLevels[key] = value;
        }
      });

      if (!node.__children) {
        const columnKeys = Object.keys(node).filter((key) => /^[A-Z]$/.test(key));
        columnKeys.forEach((col) => {
          const cIndex = col.charCodeAt(0) - "A".charCodeAt(0)
          result.push({
            rowIndex: currentRowIndex,
            column: getColumnName(cIndex),
            col,
            columnIndex: cIndex ,
            newValue: node[col] !== null ? node[col] : null,
            oldValue: null,
            ...currentLevels,
          });
        });
        currentRowIndex++;
      } else {
        node.__children.forEach((child) => traverse(child, currentLevels));
      }
    };

    inputData.forEach((node) => traverse(node));
    return result;
  };

  const rollbackSheet = async () => {
    try {
      await bulkRollback(
        processObjectForPostApi(data, true),
        'transaction_rf_group',
        8,
        null,
        resetStateAfterApi,
        null,
        client
      );
    }
    catch (e) {
      console.log('error', e)
    }
  }

  const approveSheet = async () => {
    try {
      data?.map(d => {
        d.isSubmitted = true
        d.isDraft = false
        return d
      })
      await approveOrReject(
        true,
        processObjectForPostApi(data, true),
        'transaction_rf_group',
        8,
        null,
        resetStateAfterApi
      );
    }
    catch (e) {
      console.log('error', e)
    }
  }

  const save = async () => {
    try {
      if (isSheetSubmitted && canApproverEdit)
        data?.map(d => {
          d.isDraft = false
          d.isSubmitted = true
        })
      else data?.map(d => d.isDraft = d?.isDraft ?? true)
      await createUpdateTransactions(data)
    }
    catch (e) { }
  };

  const resetStateAfterApi = async (res = null) => {
    setSheetEdit(false)
    setCanEdit(false)
    setHasSheetEdited(false)
    setIsSheetSubmitted(false)
    setCanSubmitSheet(false)
    setCanActionSheet(false)
    setCanApproverEdit(false)
    setSheetEdit(false)
    setIsRollback(false)
    setQueueMessage("Thank you for your quick submission.")
    if (res) toast.success(res.data.data)
    await getDataForTable()
  }

  const processObjectForPostApi = (data, isForAction = false, payload = {}) => {
    data?.map(d => {
      d.year = year1
      delete d['grandparent']
      delete d['parent']
      delete d['child']
      return d
    })
    let finalData = isForAction
      ? objectForApproval : Object.keys(payload)?.length
        ? {
          ...payload,
          transactions: data
        }
        : {
          masterBudgetCyclePeriodId: cyclePeriod,
          masterBusinessCategoryId: businessCategory,
          masterOrgHFMId: hfm,
          clientGroupId: client,
          masterRFAgencyId: dropdownData?.masterOrgHFMId?.find(h => h.masterBusinessCategoryId === businessCategory && h.id === hfm)?.master_rf_agency?.id,
          masterRFHeaderId: isExpense ? dropdownData?.masterBudgetCyclePeriodId?.find(h => h.id === cyclePeriod)?.expenseHeaderId : dropdownData?.masterBudgetCyclePeriodId?.find(h => h.id === cyclePeriod)?.masterRFHeaderId,
          isSheetSubmitted,
          isRollback: isRollback ?? null,
          transactions: data
        }

    if (isExpense) finalData['restated'] = preOrPostStated

    return finalData
  }

  const createUpdateTransactions = async (data, payload) => {
    setIsLoading(true);
    dispatch({ type: SET_IS_LOADING, payload: true });
    try {
      if (payload && Object.keys(payload)?.length) {
        setBusinessCategory(payload.masterBusinessCategoryId)
        setHfm(payload.masterOrgHFMId)
        setClient(payload.clientGroupId)
      }
      const res = await request.post(API_FF_Structure, processObjectForPostApi(data, false, payload))
      if (res) {
        await resetStateAfterApi(res)
      }
    }
    catch (e) { }
    finally {
      dispatch({ type: SET_IS_LOADING, payload: false });
      setIsLoading(false)
    }
  }

  return {
    hfm, client, currency, year1, year2,
    version1, version2, headers, renderTable,
    dataSource, isLoading, setIsLoading, exportData,
    changeCheckboxCell, alignHeaders,
    drawCheckboxInRowHeaders, addClassesToRows,
    saveAsExcel, handleAction, submitHeader,
    getSetupHeader, removeHeaderVersion, setDataSource,
    getDropdownData, getDataForTable, approveSheet, rollbackSheet, setRateMessage,
    save, submitSheet, dropdownData, setData, data, canApproverEdit, rateMessage,
    selectedView, setSelectedView, sheetTwo, isSheetSubmitted, canActionSheet,
    hasSheetEdited, setHasSheetEdited, businessCategory, canSubmitSheet, cyclePeriod
    , objectForApproval, canEdit, setCanEdit, queueMessage, setQueueMessage, socket, setSheetEdit
    , sheetEdit, setDropdownData, customBorders, clearDropdownsAfterEdit, handleSheetSubmit, preOrPostStated, openMonths, sheetType
  };
}
